import React from 'react'
import LayoutMod from "../common/LayoutMod"
import SplitAbout from "../elements/about/SplitAbout"
import SEO from "../common/SEO";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <LayoutMod>
                <div className="main-content">
                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-300 bg_image" data-black-overlay="4" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/monviso.jpeg)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--260 text-center">
                                        <h1 className="title display-one">We are INVENTIO.ai</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}
                </div>
                <SplitAbout />
            </LayoutMod>
        </>
    )
}

export default AboutUs;
