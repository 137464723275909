import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const AboutOneMod_1 = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail product_inspection">
                            <img className="w-100" src="./images/products/QuickInspection_2.png" alt="Quick Inspection img" />
                        </div>
                    </div>
                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">
                                    Quick Inspection
                                </h2>
                                <p>This industrial app is useful to identify and classify products and object during the production's process or in one or more specific phases of the process itself.</p>
                                <p>With a few samples you can manage multiple categories or classes of products. Thanks to the easy user experience you are able to change products recipe very fast, avoiding long shopfloor downtime.</p>
                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default btn-icon" to="/contact">Contact us<i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOneMod_1
