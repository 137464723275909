import React from 'react';
import {Link} from "react-router-dom";

const NavMod = ({onItemClick} ) => {
    return (
        <ul className="mainmenu">            
            {/* <li><Link to="/products">Products</Link></li> */}

            <li className="has-droupdown"><Link to="#">Products</Link>
                <ul className="submenu">
                    <li><Link to="/products">Industrial Apps</Link></li>
                    <li><Link to="/product/1" onClick={onItemClick}>Quick Inspection</Link></li>
                    <li><Link to="/product/2" onClick={onItemClick}>Quick Anomaly Detection</Link></li>
                    {/* <li><Link to="/products">Use Cases</Link></li>   */}
                </ul>
            </li>

            <li className="has-droupdown"><Link to="#">Applications</Link>
                <ul className="submenu">
                    <li><Link to="/industries">Industries</Link></li>
                    {/* <li><Link to="/products">Use Cases</Link></li>   */}
                </ul>
            </li>

            {/* <li><Link to="/nexus">Nexus</Link></li> */}
            <li><Link to="/about-us">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default NavMod;
