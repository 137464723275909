import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: 1,
        title: "MINIMUM TIME SETUP",
        description: "Download the app from the marketplace on standard industrial PCs or Smart Cameras. \nNo-code configuration for integrating with existing automation with full I-IOT connectivity.",
        image: "01.png",
        workingStep: [
            {
                stepTitle: "Gain your works",
                stepDescription: "Drag and drop images directly into our Industrial App",
            },
            {
                stepTitle: "Working process",
                stepDescription: "Capture live images from the ShopFloor",
            },
        ]
    },
    {
        id: 2,
        title: "TEACH TO MACHINE WITH SAMPLES",
        description: "No labeling objects needed! \nLine operators use the app to acquire examples of the tasks to be accomplished.",
        image: "02.png",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "AI models are only as good as the data you feed them. However, labeling datasets can be an arduous and time-consuming task",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Our Industrial App labeling tool quickly helps you create high-quality training data that your AI model can use for maximum accuracy",
            },
        ]
    },
    {
        id: 3,
        title: "THE AI-BRAIN LEARNS IN SECONDS",
        description: "Thanks to our apps, the AI learns in few seconds or max minutes. \nThe training is performed completely at local level!",
        image: "03.png",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Fast training with no complexity",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Immediately ready to run on the ShopFloor",
            },
        ]
    },
    {
        id: 4,
        title: "UP N’ RUNNING",
        description: "Zero-deploy time! \nThe app is already enabling the machine to perform the task!",
        image: "04.png",
        workingStep: [
            {
                stepTitle: "Follow this process",
                stepDescription: "Fast training with no complexity",
            },
            {
                stepTitle: "Working experience",
                stepDescription: "Immediately ready to run on the ShopFloor",
            },
        ]
    },
]

const replaceWithBr = (text) => {
    return text.replace(/\n/g, "<br />")
}

const conditionalRendering = (data) => {

    let result = null

    if ((data.id % 2) === 0)
    {
        result = (
            <>
                <div className="order-1 order-lg-1 col-lg-6">
                    <div className="thumbnail">
                        <img className="w-100" src={`./images/timeline/${data.image}`} alt={`${data.title}`} />
                    </div>
                </div>
                <div className="order-2 order-lg-2 col-lg-6 mt_md--40 mt_sm--40">
                    <div className="content">
                        <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <h3 className="title">{data.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: replaceWithBr(data.description)}}></p>
                            {/* <ul>
                                {data.workingStep.map((data, index)=>(
                                    <li>{data.stepDescription}</li>
                                ))}
                            </ul> */}
                        </ScrollAnimation>

                        {/* <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <p className="description">{data.description}</p>
                        </ScrollAnimation> */}                        
                    </div>
                </div>
            </>
        )
    }
    else
    {
        result = (
            <>
                <div className="order-1 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                    <div className="content">
                        <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <h3 className="title">{data.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: replaceWithBr(data.description)}}></p>
                            {/* <ul>
                                {data.workingStep.map((data, index)=>(
                                    <li>{data.stepDescription}</li>
                                ))}
                            </ul> */}
                        </ScrollAnimation>

                        {/* <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <p className="description">{data.description}</p>
                        </ScrollAnimation> */}                        
                    </div>
                </div>
                <div className="order-2 order-lg-2 col-lg-6">
                    <div className="thumbnail">
                        <img className="w-100" src={`./images/timeline/${data.image}`} alt={`${data.title}`} />
                    </div>
                </div>
            </>
        )        
    }

    return result
}

const TimelineSteps = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                {conditionalRendering(data)}                                
                            </div>
                        </div>
                    </div>                    
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineSteps;
