import React from 'react';
import GoogleMapStyle from "./GoogleMapStyle";
import { FiMail , FiMapPin } from "react-icons/fi";

const ContactOneMod = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:admin@gmail.com">info@inventio.ai</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>Via Matteotti 39, Fossano (CN) - 12045, Italy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--20 row--15">
                {/* <div className="col-lg-12">
                    <div className="rn-address" style={{ marginTop: 0 }}>
                        <div className="inner">
                            <p>We are more than interested in discussing with clients and partners about opportunities and possible applications of AI within the industrial sector.</p>
                        </div>
                    </div>
                </div>    */}
                <div className="col-lg-12 mt_md--30 mt_sm--30" style={{marginTop: 20}}>
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactOneMod;