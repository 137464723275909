import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 44.547550201416016,
      lng: 7.72119140625
    },
    zoom: 17
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      // <iframe
      //   width="600"
      //   height="450"
      //   style="border:0"
      //   loading="lazy"
      //   allowfullscreen
      //   referrerpolicy="no-referrer-when-downgrade"
      //   src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDQH0h132IgQFoLkW8VZ-R1f7VHfAQt5tQ&q=Via+Matteotti+37,Fossano+Italy">
      // </iframe>
      <div className="google-map-style-1" style={{ justifyContent: 'center', textAlign: 'center' }}>
        {/* style={{border: 0, width: '60vw', height: '50vh'}} */}
        <iframe loading="lazy" className='google-map-style-1' allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ9XoYoQxZzRIRuWJHP0x8pUI&key=AIzaSyDQH0h132IgQFoLkW8VZ-R1f7VHfAQt5tQ"></iframe>
        {/* <GoogleMapReact

          //https://www.google.com/maps/embed/v1/place?q=place_id:ChIJS8xrPwtZzRIRqROhVFu15cc&key=AIzaSyDQH0h132IgQFoLkW8VZ-R1f7VHfAQt5tQ

          bootstrapURLKeys={{ key: "AIzaSyDQH0h132IgQFoLkW8VZ-R1f7VHfAQt5tQ" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          <AnyReactComponent
            lat={44.554078409955096}
            lng={7.72641907030651}
            text="INVENTIO.ai"
          />
        </GoogleMapReact> */}
      </div>
    );
  }
}

export default GoogleMapStyle;