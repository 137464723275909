import React from 'react';

const BrandList = [
    {
        // BAUMER
        image: './images/customers/baumer-vector-logo.png',
        marginTop: 0
    },
    {
        // CUBAR
        image: './images/customers/cubar_logo.png',
        marginTop: 20
    },
    {
        // CLN GROUP
        image: './images/customers/cln-group_logo.png',
        marginTop: 20
    },
    {
        // DIG421
        image: './images/customers/DIG421_Logo.png',
        marginTop: 0
    },
    {
        // EUROPLAST
        image: './images/customers/europlast.png',
        marginTop: 20
    },
    {
        // ITT
        image: './images/customers/ITT_Logo_resized.png',
        marginTop: 0
    },
    {
        // KITT4SME
        image: './images/customers/kitt4sme_logo.png',
        marginTop: 0
    },
    {
        // MARELLI
        image: './images/customers/Magneti_Marelli_logo_resized.png',
        marginTop: 0
    },
    {
        // RIPET
        image: './images/customers/ripet_logo.png',
        marginTop: 0
    },
    {
        // ROBOPLAST
        image: './images/customers/roboplast.png',
        marginTop: 35
    },

    {
        // SIEMENS
        image: './images/customers/Siemens-Logo.png',
        marginTop: 0
    },    
    {
        // SIRP
        image: './images/customers/sirp_logo.png',
        marginTop: 20
    }
]

const BrandCustomers = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index} style={{ verticalAlign: "middle" }}>
                    <a href="javascript:void(0);" style={{ marginTop: data.marginTop }}><img src={`${data.image}`} alt="Customer Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandCustomers;
