import React from 'react';
import HeaderTwoMod from "./header/HeaderTwoMod";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";

const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <HeaderTwoMod btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <Copyright />
            </main>
        </>
    )
}
export default Layout;
