import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaUsersCog, FaCubes } from "react-icons/fa"
import { FcWorkflow } from "react-icons/fc"
import { BsFillCameraVideoFill } from "react-icons/bs"
import { GrIntegration, GrProjects } from "react-icons/gr"

const ServiceList_1 = [
    {
        icon: <FcWorkflow color='black'/>,
        title: 'Flow Manager',
        description: 'Low code tool to configure the business/production processes and integrations.'
    },
    {
        icon: <BsFillCameraVideoFill color='black' />,
        title: 'Multi-Camera',
        description: 'Manage more cameras at the same time.'
    },
    {
        icon: <FaCubes color='black' />,
        title: 'Multi-Instance',
        description: 'Distribute multiple Industrial Apps in the same environment.'
    }
]

const ServiceList_2 = [
    {
        icon: <GrIntegration color='black' />,
        title: 'Integrations',
        description: 'Integrate multiple Industrial Apps together.'
    },
    {
        icon: <GrProjects color='black' />,
        title: 'Multi-Project Management',
        description: 'Multiple projects can be managed (recipes) in easy way.'
    },
    // {
    //     icon: <FaExchangeAlt />,
    //     title: 'One-click Change Project',
    //     description: 'Change the runtime project (recipe) in a matter of seconds.'
    // },
    {
        icon: <FaUsersCog color='black' />,
        title: 'Users & ACL Configurations',
        description: 'Users with different level of permissions and access (roles) configuration.'
    },
]

const ServiceFourMod = ({textAlign, serviceStyle}) => {
    return (
        <>
            <div className="row row--15 service-wrapper">
                {ServiceList_1.map( (val , i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className={`service ${serviceStyle} ${textAlign}`}>
                                <div className="inner">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))}
            </div>
            <div className="row row--15 service-wrapper">
                {ServiceList_2.map( (val , i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className={`service ${serviceStyle} ${textAlign}`}>
                                <div className="inner">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))}
            </div>
        </>
        
    )
}
export default ServiceFourMod;