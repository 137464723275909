import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SplitOneMod = (item) => {
    return (
        <div className="rn-splite-style">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="col-lg-12 col-xl-12 col-12">
                            <div className="split-inner">
                                <h4 className="title" style={{textAlign: 'center'}}>Process Steps</h4>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                            <div className="thumbnail image-left-content">
                                <img src="./images/bg/process_steps.png" alt="split Images" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="col-lg-12 col-xl-12 col-12">
                            <div className="split-inner">
                                <h4 className="title" style={{textAlign: 'center'}}>Process Result</h4>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-12 col-12">
                            <div className="thumbnail image-left-content">
                                <img src="./images/bg/process_result.png" alt="split Images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SplitOneMod
