import React from 'react';
import SEO from "../common/SEO";
import LayoutMod from "../common/LayoutMod";
import AboutTwoMod from "../elements/about/AboutTwoMod";
import { useParams } from 'react-router-dom';
import ProductData from "../data/products/ProductData.json";

const Product = () => {
    const { selectedProductId } = useParams();

    let productId = parseInt(selectedProductId)
    let product = ProductData.filter(x => x.id === productId)[0]

    return (
        <>
            <SEO title="" />
            <LayoutMod>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area">
                        <div className="container">
                            <AboutTwoMod product={product} />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </LayoutMod>
        </>
    )
}
export default Product;