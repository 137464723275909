import React from 'react';

const Industry_2 = (props) => {

    const { data } = props;

    return (
        <>
            <div className="rn-splite-style">
                <div className="split-wrapper">
                    <div className="row no-gutters radius-10 align-items-center">
                        <div className="col-lg-12 col-xl-12 col-12">
                            <div className="rn-contact-address">
                                <div className="rn-address">
                                    <div className="inner">
                                        <div className="row">
                                            <div className="col-lg-6 col-xl-6 col-12 col-sm-12" style={{ textAlign: 'center' }}>
                                                <h2>{data.title}</h2>
                                                <br/>
                                                {data.description}
                                            </div>
                                            <div className="col-lg-6 col-xl-6 col-12 col-sm-12" style={{ textAlign: 'center' }}>
                                                <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.image}`} alt="Industry 2 img" />
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </>

        // <div className="about-area about-style-4 rn-section-gap">
        //     <div className="container">
        //         <div className="row row--40 align-items-center">
        //             <div className="col-lg-6">
                        
        //             </div>
        //             <div className="col-lg-6 mt_md--40 mt_sm--40">
        //                 <div className="content">
        //                     <div className="inner">
        //                         <h3 className="title">Doob specializes in <br /> small <strong>Corporate Business.</strong></h3>
        //                         <p>

        //                         </p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Industry_2
