import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const AdvanceTabProcess = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-2 order-lg-1 col-lg-4 col-md-12 col-sm-12 col-12 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Scalable, Distributed and Portable</h4>
                                        <p className="description">Can run anywhere, distributed on every infrastructure and scalable with multiple instances.
                                            {/* - integrable with any other system
                                            - multiple instances to distribute the AI workload between different calculation units
                                            - ready to be deployed and executed in different infrastructure scenarios (only edge, on-premise, hybrid and cloud)
                                            - hardware agnostic and multi-OS support */}
                                        </p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Ready for the future</h4>
                                        <p className="description">Hardware agnostic, multi-OS support, can be integrated with any other system (plug & play low-coding process flow configurator).
                                        {/* Thanks to our low coding tool you are able to:
                                            - create multiple process flows with inputs and outputs in easy way */}
                                        </p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">Safe</h4>
                                        <p className="description">All the data processed remain at local level. All the company data and the information privacy are guaranteed.</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/banner/edge.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/banner/future.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="./images/banner/data_safety.png" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabProcess;