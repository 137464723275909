import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const AboutOneMod_2 = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">
                                    Quick Anomaly Detection
                                </h2>
                                <p>This industrial app is useful to identify defects and anomalies on the products or object during the production's process or in one or more specific phases of the process itself.</p>
                                <p>With a few good samples you can easily found the products anomalies. Thanks to this approach is not necessary anymore collect all the products' defects identified and maintain an anomaly registry.</p>
                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default btn-icon" to="/contact">Contact us<i className="icon"><FiArrowRight /></i></Link>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="thumbnail product_anomaly">
                            <img className="w-100" src="./images/products/QuickAnomalyDetection_2.png" alt="Quick Anomaly img" />
                        </div>
                    </div>                    
                </div>
            </div>   
        </div>
    )
}

export default AboutOneMod_2
