import React from 'react';
import { IoCubeSharp } from "react-icons/io5";
import {Link} from "react-router-dom";
import { BsFillGearFill } from "react-icons/bs"
import { FaIndustry } from "react-icons/fa"
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FaIndustry />,
        title: 'Industries',
        description: 'Industries can build upon their existing assets and know-how with innovative Artificial Intelligence solutions to improve quality, costs and timings.'
    },
    {
        icon: <BsFillGearFill />,
        title: 'OEMs',
        description: 'Thanks to our industrial apps built on our proprietary platform, OEMs can promptly integrate advanced functionalities in their products.'
    },
    {
        icon: <IoCubeSharp />,
        title: 'Systems Integrators',
        description: 'INVENTIO.ai tools speak the same language of shop-floor workers. They are designed to be easy to integrate and ready-to-go for production.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600">{val.title}</h4>
                                <p className="description b1 mb--0">{val.description}</p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;