import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";
import { FiArrowRight  } from "react-icons/fi";
import Typed from 'react-typed';

const AboutTwo = (props) => {

    const { product } = props

    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail product">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${product.image}`} alt="Product Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h2 className="title mt--10">{product.name}</h2>
                                    <h2 className="title theme-gradient">
                                        <Typed
                                            strings={product.adjectives}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h2>                                    
                                </ScrollAnimation>

                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p>{product.description}</p>
                                    <ul className="list-icon">
                                        {product.steps.map((data, index) => (
                                            <li key={index}> {data.id}. {data.text}</li>
                                        ))}
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <div className="read-more-btn mt--40">
                                        <Link className="btn-default btn-icon" to="/contact">Contact us<i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                    <br />
                                    <br />
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutTwo;
